import axiosClient from './axiosClient';

const authApi = {
  login: (username, password, is_change_password) => {
    const url = '/auth/login';
    const payload = {
      username,
      password,
      is_change_password,
    };
    return axiosClient.post(url, payload);
  },
  getUser: () => {
    const url = '/auth/user';
    return axiosClient.get(url);
  },
  chagePassword: (payload) => {
    const url = '/auth/change_password';
    return axiosClient.post(url, payload);
  },
  createUser: (payload) => {
    const url = '/auth/register';
    return axiosClient.post(url, payload);
  },
  getListUsers: (term, page, size) => {
    let url;
    if (term && term !== '') {
      url = `/auth/users?term=${term}&page=${page}&size=${size}`;
    } else {
      url = `/auth/users?page=${page}&size=${size}`;
    }

    return axiosClient.get(url);
  },
  updateUser: (userId, payload) => {
    const url = `/auth/users/${userId}`;
    return axiosClient.put(url, payload);
  },
  updateStatus: (userId, status) => {
    const url = `/auth/users/${userId}/${status}`;
    return axiosClient.post(url);
  },
  getUserRecommend: (text) => {
    const url = `/auth/recommend?text=${text}`;
    return axiosClient.get(url);
  },
  resetPassword: (userId, password) => {
    const url = '/auth/reset_password';
    const payload = {
      user_id: userId,
      password,
    };
    return axiosClient.post(url, payload);
  },
};

export default authApi;
