import React from 'react';
import { Oval } from 'react-loader-spinner';

import styles from './style.module.css';

function Loading() {
  return (
    <div className={styles.container}>
      <div className={styles.loading_container}>
        <Oval
          height={80}
          width={80}
          color="#2980b9"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#3498db"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        {/* <span className={styles.text}>Loading...</span> */}
      </div>
    </div>
  );
}

export default Loading;
