import React from 'react';

import styles from './style.module.css';

function FormControl({ title, children }) {
  return (
    <div className={styles.container}>
      <span className="text-medium-12 font-500">{title}</span>
      {children}
    </div>
  );
}

export default FormControl;
