import ListReport from './components/ListReport';
import styles from './style.module.css';

const Report = () => {
  return (
    <div className={styles.container}>
      <ListReport />
    </div>
  );
};

export default Report;
