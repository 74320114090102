import './App.css';

import { AuthProvider } from 'contexts/authenticationContext';
import Router from 'routers';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router />
      </AuthProvider>
    </div>
  );
}

export default App;
