import React from 'react';

import Header from './components/Header';
import styles from './style.module.css';

function Layout({ children }) {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.main}>{children}</div>
    </div>
  );
}

export default Layout;
