import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Tag } from 'antd';
import keywordApi from 'apis/keyword';
import CustomTable from 'components/ui/CustomTable';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'components/ui/Notification';
import moment from 'moment';
import { useEffect, useState } from 'react';

import styles from './ListKeyword.module.css';

function ListKeyword() {
  const [keywords, setKeywords] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');
  const [keywordLabel, setKeywordLabel] = useState('');

  const handleFetchKeywords = async () => {
    setKeywords(null);
    const res = await keywordApi.getKeywords();
    setKeywords(res);
  };

  useEffect(() => {
    (async () => {
      handleFetchKeywords();
    })();
  }, []);

  const handleAddKeywords = async () => {
    if (isCreating) return;
    setIsCreating(true);
    try {
      await keywordApi.createKeywords({
        label: keywordLabel,
        value: newKeyword,
      });
      showSuccessNotification('Add new keyword successfully');
      handleFetchKeywords();
      setNewKeyword('');
      setKeywordLabel('');
    } catch {
      showErrorNotification('Something went wrong');
    }
    setIsCreating(false);
  };

  const handleDeleteKeyword = async (id) => {
    try {
      await keywordApi.deleteKeyword(id);
      showSuccessNotification('Delete keyword successfully');
      handleFetchKeywords();
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  const columns = [
    {
      title: 'Keyword',
      key: 'keyword',
      render: (keyword) => {
        return (
          <div>
            <span className={styles.text}>
              <Tag style={{ background: 'rgb(254, 209, 48)' }}>
                {keyword.label ?? keyword.value}
              </Tag>
            </span>
            <span>
              {keyword.label &&
                keyword.value !== keyword.label &&
                keyword.value}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (created) => {
        return (
          <span className={styles.text}>
            {moment(created).format('DD MMM, YYYY HH:mm')}
          </span>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '_id',
      key: '_id',
      width: '12rem',
      render: (id) => {
        return (
          <div>
            <Popconfirm
              title="Are you sure to delete this keyword"
              onConfirm={async () => handleDeleteKeyword(id)}
            >
              <Button size="small" type="text">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      <CustomTable
        rowKey="_id"
        title="Keywords"
        total={keywords?.length}
        desc="Managing keywords"
        columns={columns}
        datasource={keywords}
        loading={!keywords}
        scrollTable={{ x: '100%' }}
        pageObj={{
          page: 1,
          pageSize: keywords?.length ?? 0,
        }}
        HeaderActionComponent={
          <>
            <Input
              title="Label"
              value={keywordLabel}
              onChange={(e) => setKeywordLabel(e.target.value)}
              style={{ width: 200 }}
              placeholder="Keyword label (optional)"
            />
            <Input
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              style={{ width: 320 }}
              placeholder="Keyword value"
            />
            <Button
              onClick={handleAddKeywords}
              loading={isCreating}
              type="primary"
            >
              Add keyword
            </Button>
          </>
        }
      />
    </div>
  );
}

export default ListKeyword;
