import { useEffect } from 'react';

function useOutside(containerRef, parentRef, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (
        !containerRef.current ||
        containerRef.current.contains(event.target)
      ) {
        return;
      }
      if (!parentRef.current || parentRef.current.contains(event.target)) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [containerRef, handler]);
}

export default useOutside;
