import { configureStore } from '@reduxjs/toolkit';

import mediaPlayerReducer from './mediaPlayerSlice';
import monitoringReducer from './monitoringSlice';
import reportReducer from './reportSlice';
import storeReducer from './storeSlice';
import syncReducer from './sync';
import userReducer from './userSlice';
import videoReducer from './videoSlice';

const store = configureStore({
  reducer: {
    monitoring: monitoringReducer,
    store: storeReducer,
    user: userReducer,
    video: videoReducer,
    sync: syncReducer,
    report: reportReducer,
    mediaPlayer: mediaPlayerReducer,
  },
});

export default store;
