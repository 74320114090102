import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPlaying: false,
  slideValue: 0,
  currentTime: null,
  speedRate: 1,
  seekTo: 0,
};

const mediaPlayerSlice = createSlice({
  name: 'mediaPlayer',
  initialState: initialState,
  reducers: {
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setSlideValue: (state, action) => {
      state.slideValue = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setSpeedRate: (state, action) => {
      state.speedRate = action.payload;
    },
    setSeekTo: (state, action) => {
      state.seekTo = action.payload;
    },
    resetMediaPlayer: (state) => {
      return initialState;
    },
  },
});

export const {
  setIsPlaying,
  setSlideValue,
  setSeekTo,
  setSpeedRate,
  resetMediaPlayer,
} = mediaPlayerSlice.actions;

export default mediaPlayerSlice.reducer;
