import Layout from 'components/layout';
// components
import Loading from 'components/ui/Loading';
import { Role } from 'constants/global';
import { useAuthProvider } from 'contexts/authenticationContext';
import Keywords from 'pages/keywords';
import Report from 'pages/report';
import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

const Login = React.lazy(() => import('pages/login'));
const Dashboard = React.lazy(() => import('pages/dashboard'));
const Store = React.lazy(() => import('pages/store'));
const UserManagement = React.lazy(() => import('pages/user_management'));
const Playback = React.lazy(() => import('pages/playback'));
const PlaybackSO = React.lazy(() => import('pages/playback/so'));
const PlaybackSODetail = React.lazy(
  () => import('pages/playback/so/so_detail')
);
const PlaybackTime = React.lazy(() => import('pages/playback/time'));
const Dictionary = React.lazy(() => import('pages/dictionary'));
const TranscriptTasks = React.lazy(() => import('pages/transcript_tasks'));
const NotFound = React.lazy(() => import('pages/not_found'));

function Router() {
  const { currentUser } = useAuthProvider();
  const history = useHistory();
  const location = useLocation();

  const validURL = {};
  validURL[Role.ADMIN] = [
    '/dashboard',
    '/store',
    '/user',
    '/playback',
    '/playback/so',
    '/playback/time',
    '/not-found',
    '/dictionary',
    '/transcript-tasks',
    '/keywords',
    '/report',
  ];
  validURL[Role.OPERATOR] = [
    '/dashboard',
    '/store',
    '/playback',
    '/playback/so',
    '/playback/time',
    '/transcript-tasks',
    '/not-found',
    '/keywords',
    '/report',
  ];
  validURL[Role.ACCOUNT] = ['/user', '/not-found'];
  validURL[Role.QA] = [
    '/playback',
    '/playback/so',
    '/playback/time',
    '/transcript-tasks',
    '/not-found',
  ];
  validURL[Role.STORE] = [
    '/playback',
    '/playback/so',
    '/playback/time',
    '/not-found',
  ];

  useEffect(() => {
    const path = location.pathname;

    if (currentUser !== null) {
      if (path === '/login') {
        if (
          currentUser.role === Role.ADMIN ||
          currentUser.role === currentUser.OPERATOR
        ) {
          history.push('/dashboard');
        } else if (currentUser.role === Role.ACCOUNT) {
          history.push('/user');
        } else {
          history.push('/playback/so');
        }
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (location.pathname === '/playback') {
      history.push('/playback/so');
    }
  }, [location.pathname]);

  return (
    <Switch>
      {currentUser ? (
        <Layout>
          <Route
            path="/dashboard"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Dashboard {...props} />
              </React.Suspense>
            )}
            exact
          />
          <Route
            path="/store"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Store {...props} />
              </React.Suspense>
            )}
            exact
          />
          <Route
            path="/user"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <UserManagement {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/playback"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Playback {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/playback/so"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <PlaybackSO {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/playback/so/:soId"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <PlaybackSODetail {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/playback/time"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <PlaybackTime {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/dictionary"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Dictionary {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/transcript-tasks"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <TranscriptTasks {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/keywords"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Keywords {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/report"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Report {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/not-found"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <NotFound {...props} />
              </React.Suspense>
            )}
            exact
          />
        </Layout>
      ) : (
        <>
          <Route path="/" exact>
            <Redirect to="/login"></Redirect>
          </Route>
          <Route
            path="/login"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Login {...props} />
              </React.Suspense>
            )}
            exact
          />
        </>
      )}
    </Switch>
  );
}

export default Router;
