import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allStore: [],
  stores: [],
  allStoreFilter: [],
  storesFilter: [],
  filterProvinces: [],
  filterTags: [],
  filterStore: ['all'],
  filterDate: null,
  filterKeywords: ['all'],
  loading: false,
};

const reportSlice = createSlice({
  name: 'report',
  initialState: initialState,
  reducers: {
    changeFilterProvinces: (state, action) => {
      state.filterProvinces = action.payload;
    },
    changeFilterTags: (state, action) => {
      state.filterTags = action.payload;
    },
    changeFilterStores: (state, action) => {
      state.filterStore = action.payload;
    },
    changeFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    changeFilterKeywords: (state, action) => {
      state.filterKeywords = action.payload;
    },
    setAllStores: (state, action) => {
      state.allStore = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    setAllStoresFilter: (state, action) => {
      state.allStoreFilter = action.payload;
    },
    setStoresFilter: (state, action) => {
      state.storesFilter = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const reportAction = reportSlice.actions;

export default reportSlice.reducer;
