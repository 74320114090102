import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visibleDetail: false,
  selectBox: null,
  selectProgressType: 'sync',
};
const syncSlice = createSlice({
  name: 'sync_status',
  initialState: initialState,
  reducers: {
    setVisibleDetail: (state, action) => {
      state.visibleDetail = action.payload;
    },
    setSelectBox: (state, action) => {
      state.selectBox = action.payload;
    },
    setSelectProgressType: (state, action) => {
      state.selectProgressType = action.payload;
    },
  },
});

export const syncAction = syncSlice.actions;

export default syncSlice.reducer;
