import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import LogoImage from 'assets/images/logo.png';
import { useAuthProvider } from 'contexts/authenticationContext';
import { Link, useLocation } from 'react-router-dom';

import { navMenu } from '../constants';
import Avatar from './Avatar';
import styles from './Header.module.css';

function Header() {
  const { currentUser } = useAuthProvider();
  const location = useLocation();

  const handleMenuScrollLeft = () => {
    const menuContainer = document.querySelector('#menu_container');
    menuContainer.scrollLeft -= menuContainer.clientWidth / 3; // @NOTE:Adjust scroll amount as needed
  };

  const handleMenuScrollRight = () => {
    const menuContainer = document.querySelector('#menu_container');
    menuContainer.scrollLeft += menuContainer.clientWidth / 3; // @NOTE: Adjust scroll amount as needed
  };

  const renderMenuItems = (menu) =>
    menu.map((item) => {
      const isMatch =
        item.path === location.pathname ||
        (item.path === '/playback' &&
          ['/playback', '/playback/time'].includes(location.pathname));
      return (
        <div
          className={styles.menu_item_container}
          key={`${item.path}_menu_item`}
          style={{
            background: isMatch ? '#1B9CFC' : null,
          }}
        >
          <Link
            key={`${item.path}_link`}
            to={item.path}
            className={styles.text_menu}
          >
            {item.label}
          </Link>
        </div>
      );
    });

  return (
    <div className={styles.container}>
      <img src={LogoImage} className={styles.logo} alt="NamiTech Logo" />
      <>
        {currentUser && (
          <div className={styles.wrapper}>
            <Button
              type="ghost"
              shape="circle"
              icon={<LeftCircleOutlined />}
              onClick={handleMenuScrollLeft}
              className={styles.menu_scroll_button_left}
            />
            <div id="menu_container" className={styles.menu_container}>
              {renderMenuItems(navMenu[currentUser.role])}
            </div>
            <Button
              id="menu_scroll_button_right"
              type="ghost"
              shape="circle"
              icon={<RightCircleOutlined />}
              onClick={handleMenuScrollRight}
              className={styles.menu_scroll_button_right}
            />
          </div>
        )}
        <Avatar />
      </>
    </div>
  );
}

export default Header;
