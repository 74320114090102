export const TOKEN_KEY = 'TOKEN';
export const EXPIRED_KEY = 'EXPIRED';
export const USERNAME_KEY = 'USERNAME';
export const Role = {
  ADMIN: 'admin',
  QA: 'qa',
  STORE: 'store',
  OPERATOR: 'operator',
  ACCOUNT: 'account',
};

export const DeviceStatus = {
  NEW: -1,
  GOOD: 0,
  ERROR: 1,
  OFFLINE: -2,
};

export const DeviceStatusList = [
  {
    value: DeviceStatus.NEW,
    title: 'New',
  },
  {
    value: DeviceStatus.GOOD,
    title: 'Good',
  },
  {
    value: DeviceStatus.ERROR,
    title: 'Error',
  },
  {
    value: DeviceStatus.OFFLINE,
    title: 'Offline',
  },
];

const statusMap = {};
statusMap[DeviceStatus.NEW] = 'New';
statusMap[DeviceStatus.GOOD] = 'Good';
statusMap[DeviceStatus.ERROR] = 'Error';
statusMap[DeviceStatus.OFFLINE] = 'Offline';

export const StatusMap = statusMap;

export const SyncStatusColor = {
  BAD: '#d9f7be',
  NORMAL: '#95de64',
  GOOD: '#52c41a',
  EXCELLENT: '#237804',
};

export const TaskStatus = {
  FAILED: -1,
  UPLOADED: 0,
  NO_AUDIO: 1,
  TRANSCRIBING: 2,
  TRANSCRIBED: 3,
  NO_VIDEO: 4,
  FAILED_THRESHOLD_EXCEEDED: 5,
};

export const colorStatusMap = {
  sync: {
    BAD: '#d9f7be',
    NORMAL: '#95de64',
    GOOD: '#52c41a',
    EXCELLENT: '#237804',
  },
  audio: {
    BAD: '#bae7ff',
    NORMAL: '#69c0ff',
    GOOD: '#1890ff',
    EXCELLENT: '#0050b3',
  },
  video: {
    BAD: '#b5f5ec',
    NORMAL: '#5cdbd3',
    GOOD: '#13c2c2',
    EXCELLENT: '#006d75',
  },
};

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';

export const DEFAULT_VALUE = {
  PAGE: 1,
  LIMIT: 20,
};
