import Doc from 'assets/docs/03. Hướng dẫn sử dụng dashboard_v1.0.pdf';
import { showConfirmPopup } from 'components/ui/Notification';
import { useAuthProvider } from 'contexts/authenticationContext';
import useOutside from 'hooks/useOutside';
import React, { useRef, useState } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdLogout } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';

import styles from './Avatar.module.css';
import ChangePassword from './ChangePassword';

function Avatar() {
  const { currentUser, logout } = useAuthProvider();
  const parentRef = useRef();
  const username = currentUser.username;
  const [visibleExtension, setVisibleExtension] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  return (
    <div
      ref={parentRef}
      className={styles.container}
      onClick={() => {
        setVisibleExtension(!visibleExtension);
      }}
    >
      <span>{username[0].toUpperCase()}</span>
      {visibleExtension && (
        <Extension
          setVisible={setVisibleExtension}
          username={username}
          logout={logout}
          parentRef={parentRef}
          setVisiblePassword={setVisiblePassword}
        />
      )}

      {visiblePassword && (
        <ChangePassword
          visible={visiblePassword}
          setVisible={setVisiblePassword}
        />
      )}
    </div>
  );
}

function Extension({
  parentRef,
  setVisible,
  username,
  logout,
  setVisiblePassword,
}) {
  const ref = useRef();

  useOutside(ref, parentRef, () => {
    setVisible(false);
  });

  const handleLogout = () => {
    showConfirmPopup('Do you want to logout?', () => {
      logout();
    });
  };

  return (
    <div className={styles.extension_container} ref={ref}>
      <div className={styles.extension_header}>
        <FaRegUserCircle className={styles.icon_1} />
        <span className={styles.text}>{username}</span>
      </div>

      <div className={styles.extension_main}>
        <a className={styles.extension_item} href={Doc} download>
          <HiOutlineDocumentText className={styles.icon_2} />
          <span className={styles.text_2}>Documentation</span>
        </a>

        <div
          className={styles.extension_item}
          onClick={() => {
            setVisiblePassword(true);
          }}
        >
          <RiLockPasswordLine className={styles.icon_2} />
          <span className={styles.text_2}>Change password</span>
        </div>

        <div className={styles.extension_item} onClick={handleLogout}>
          <MdLogout className={styles.icon_2} />
          <span className={styles.text_2}>Logout</span>
        </div>
      </div>
    </div>
  );
}

export default Avatar;
