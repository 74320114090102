import { LoadingOutlined } from '@ant-design/icons';
import Logo from 'assets/images/logo.png';

import styles from './style.module.css';

function Loading() {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <LoadingOutlined className={styles.loading_icon} />
        <div className={styles.logo_container}>
          <img src={Logo} className={styles.image} />
        </div>
      </div>
    </div>
  );
}

export default Loading;
